import StoreIds from './storeIds';
import { v4 as uuidv4 } from 'uuid';

export enum ToastStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export type TToastStatus =
  | ToastStatus.SUCCESS
  | ToastStatus.WARNING
  | ToastStatus.ERROR;

interface IToast {
  text: string;
  status: TToastStatus;
  id: string;
}
type ToastPayload = { timeout?: number; text: string };

const defaultTimeout = 4000;

const createToast = (text: string, status: TToastStatus): IToast => ({
  text,
  status,
  id: uuidv4(),
});

export const useToastStore = defineStore(StoreIds.TOAST, {
  state: (): { toasts: IToast[] } => ({
    toasts: [],
  }),
  actions: {
    updateState(payload: ToastPayload, status: TToastStatus) {
      const { text, timeout } = payload;

      const toast = createToast(text, status);

      this.toasts.push(toast);

      setTimeout(() => {
        this.toasts = this.toasts.filter((t) => t.id !== toast.id);
      }, timeout ?? defaultTimeout);
    },
    success(payload: ToastPayload) {
      this.updateState(payload, ToastStatus.SUCCESS);
    },

    warning(payload: ToastPayload) {
      this.updateState(payload, ToastStatus.WARNING);
    },

    error(payload: ToastPayload) {
      this.updateState(payload, ToastStatus.ERROR);
    },
  },
});
